import React from 'react';
import { navigate } from 'gatsby';
import { apiUrl, postRequest } from '../utils/request';
import { isLoggedIn, logout } from '../utils/auth';
import { setPageAlert } from '../utils/page-alerts';
import Layout from '../components/layout';
import SEO from '../components/seo';

class Logout extends React.Component {

  componentDidMount() {
    this.performLogout();
  }

  performLogout = async () => {
    if (isLoggedIn()) {
      await postRequest(`${apiUrl}/auth/logout`, {});
    }
    logout(() => {
      setPageAlert({
        body: "You have been signed out.",
      });
      navigate('/signin');
    });
  }

  render() {
    // console.log(this.props, 'props... where is history?');
    return (
      <Layout>
        <SEO title="Logout" />
        <section className="">
          <div className="container px-5 py-24 mx-auto flex flex-wrap items-center">
            <div className="lg:w-2/6 md:w-1/2 flex flex-col md:mx-auto w-full f text-center">
              <h3>You are being logged out...</h3>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export default Logout;
